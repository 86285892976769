<template>
  <div class="gallery-wrap">
    <div
      class="gallery-item-wrap"
      :class="{'big':i == active,'small':i != active && datasource.length > 2,'smalltop':i != active && datasource.length <3}"
      v-for="(item, i) in datasource"
      :key="i"
      @mouseenter="childchange(i)"
      ref="galleryList"
    >
      <div @click="clickDetail(item)">
        <!-- 标题 -->
        <div class="title-wrap">
          <img
            v-if="active == i"
            id="titleicon"
            :src="item.articleSource"
          />
          <img
            v-else
            id="titleicon2"
            :src="item.articleSource"
          />
          <h2>{{ item.title }}</h2>
        </div>

        <!-- 描述 -->
        <div class="desc-wrap">
          <span>{{ item.keyword1 }}</span>
          <span>{{ item.keyword2 }}</span>
        </div>

        <!-- 图片 -->
        <div class="img-wrap">
          <img
            :src="item.img"
            alt=""
          />
        </div>

        <!-- 按钮 -->
        <div
          class="btn-wrap"
          v-if="i == active"
        >
          <button>查看详情</button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "Gallery",
  props: ["datasource"],
  data() {
    return {
      active:
        this.datasource.length > 2
          ? -1
          : 0,
    };
  },

  mounted() {
    console.log("-----------", this.datasource);
  },
  methods: {
      childchange(val){
          this.active = val
          this.$emit("childchange")
      },
      parentclickme(){
          this.active = -1
      },
    clickDetail(item) {
      this.$router.push({
        path: "/article",
        query: {
          articleID: item.articleID,
          articleType: item.articleType,
        },
      });
    },
  },
  watch: {
    "datasource.length"() {
      this.active =
        this.datasource.length <3
          ? 0
          : -1;
    },
    active(newIndex, oldIndex) {
      this.$nextTick(() => {
        this.$refs.galleryList[newIndex].classList.add("animation-fade");
        this.$refs.galleryList[oldIndex].classList.remove("animation-fade");
      });
    },
  },
};
</script>

<style lang="less" scoped>
.animation-fade {
  animation: fade 0.25s ease-in-out;
}
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.gallery-wrap {
  display: flex;
  justify-content: center;

  .small,
  .big {
    // height: 30.3125rem /* 485/16 */;
    border-radius: 1rem /* 16/16 */;
    cursor: pointer;
  }
}

.big {
  width: 25% /* 400/16 */;
  border: 1px solid #5555ff;
  background-color: #fff;

  .title-wrap {
    margin-top: -2px;
    padding-left: 2rem /* 106/16 */;
    height: 3.75rem /* 60/16 */;
    display: flex;
    align-items: center;
    background-color: #5555ff;
    border-radius: 1rem 1rem 0 0;
    // background-image: url(https://www.xiaoe-tech.com/images/homePage/background-top.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    color: #fff;
    font-size: 1.25rem /* 20/16 */;
  }

  .desc-wrap {
    display: flex;
    flex-direction: column;
    padding: 1.0625rem /* 17/16 */ 0 0 3.25rem /* 52/16 */;
    font-size: 1rem /* 16/16 */;
    line-height: 1.5rem /* 24/16 */;
    color: #353535;
    text-shadow: 0 10px 16px #f5f9ff;
  }

  .img-wrap {
    width: 306px;
    margin: 12px auto 0;

    img {
      width: 100%;
    }
  }

  .btn-wrap button {
    width: 6.25rem /* 100/16 */;
    // height: 2.625rem /* 42/16 */;
    line-height: 2.625rem /* 42/16 */;
    background: #5555ff;
    border-radius: 1.3125rem /* 21/16 */;
    display: block;
    margin: 1.0625rem /* 17/16 */ auto 0;
    font-size: 1rem /* 16/16 */;
    font-weight: 500;
    color: #fff;
    margin-bottom: 1rem;
    cursor: pointer;
  }
}
.small {
  width: 15.5rem /* 200/16 */;
  border: 1px solid #dceaff;
  text-align: center;
  background-color: #fff;

  .title-wrap {
    margin-top: 4.25rem /* 68/16 */;
    height: 1.375rem /* 22/16 */;
    font-size: 1rem /* 16/16 */;
    font-weight: 500;
    color: #333;
    line-height: 1.375rem /* 22/16 */;
    margin-bottom: 0.5rem /* 8/16 */;
  }

  .desc-wrap {
      margin-top: 4.25rem /* 68/16 */;
    display: flex;
    padding: 0.2rem;
    flex-direction: column;
    font-size: 0.75rem /* 12/16 */;
    color: #333;

    span {
      line-height: 1.125rem /* 18/16 */;
    }
  }

  .img-wrap {
    width: 160px;
    margin: 76px auto 50px;

    img {
      width: 100%;
    }
  }
}
.smalltop {
  width: 19rem /* 200/16 */;
  border: 1px solid #dceaff;
  text-align: center;
  background-color: #fff;
    border-radius: 1rem /* 16/16 */;
  .title-wrap {
    margin-top: 4.25rem /* 68/16 */;
    height: 1.375rem /* 22/16 */;
    font-size: 1rem /* 16/16 */;
    font-weight: 500;
    color: #333;
    line-height: 1.375rem /* 22/16 */;
    margin-bottom: 0.5rem /* 8/16 */;
  }

  .desc-wrap {
      margin-top: 4.25rem /* 68/16 */;
    display: flex;
    padding: 0.2rem;
    flex-direction: column;
    font-size: 0.75rem /* 12/16 */;
    color: #333;

    span {
      line-height: 1.125rem /* 18/16 */;
    }
  }

  .img-wrap {
    width: 160px;
    margin: 76px auto 50px;

    img {
      width: 100%;
    }
  }
}
#titleicon {
  height: 2vw;
  margin-right: 0.3vw;
}
#titleicon2 {
  height: 2vw;
  filter: drop-shadow(0px 0 0 rgb(0, 0, 0));
}
</style>