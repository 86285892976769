<template>
  <!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
  <div id="Integratedbusinessdevelopmentplatform" style="width:100vw">
    <!-- <header>
      <nav-swiper :carousels="navCarousels"
                  @on-click="clickCarousel"></nav-swiper>
    </header> -->
    <!-- <div v-for="imginfo in imgList" :key="imginfo.imgID" style="height:50vh">
        <img :src="imginfo.imgURL"  >
    </div> -->
    <div id="a628b0b6">标准化开发流程</div>
    <div class="flex-wrap">
      <img src="../assets/img-cd8dd947.png" class="section1-img" />
    </div>
    <section class="section2">
      <header>
        <h4>统一中后台</h4>
      </header>
      <!-- <main v-if="section2.carousels.length">
        <dal-comp-carousel :carouselData="section2.carousels"></dal-comp-carousel>
      </main> -->
      <div class="gallery-container">
        <Gallery :datasource="gallery"></Gallery>
      </div>
    </section>
    <div id="a336a01c">全形态前端展现</div>
    <el-carousel
      :interval="4000"
      indicator-position="none"
      type="card"
      :height="imgHeight + 'px'"
    >
      <el-carousel-item v-for="(item, i) in section3.carousels" :key="i">
        <div>
          <img :src="item.img" />
          <div class="label">{{ item.label }}</div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div id="a383d1ed">
      <dal-compfloat id="cmpa383d1ed"> </dal-compfloat>
    </div>
  </div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
import {
  // DALINEWEB_IMGCENTER_V0X0X1_getImgList,
  DALINEWEB_ARTICLECENTER_V0X0X1_getArticlewithToped,
  // DALINEWEB_ARTICLECENTER_V0X0X1_getArticleInfo
} from "../api/dalapi.js";
import Gallery from "@/components/Gallery";
//在此注释下方添加自定义函数
//此注释下方为自动引用部分，请不要修改
import dal_compfloat from "../components/dal-compfloat";
// import dalCompCarousel from "../components/dal-comp-carousel.vue";
// import NavSwiper from '../components/nav_swiper'
export default {
  components: {
    "dal-compfloat": dal_compfloat,
    // dalCompCarousel,
    // NavSwiper
    Gallery,
  },
  data() {
    return {
      //在此注释下方添加自定义变量
      op_e98e5562: [],
      val_e98e5562: "",
      imgList: [],
      // 区域二
      dataList: [],
      // section2: {
      //   carousels: [],
      //   activeCarousel: 0,
      // },
      gallery: [],
      section3: {
        carousels: [
          {
            img: require("../assets/img-ad01bb37.png"),
            label: "数据大屏",
          },
          {
            img: require("../assets/img-a18bb9bf.png"),
            label: "Web端",
          },
          {
            img: require("../assets/img-a31526a9.png"),
            label: "PC端",
          },
          {
            img: require("../assets/img-ab288f7a.png"),
            label: "小程序",
          },
          {
            img: require("../assets/img-f8a368c6.png"),
            label: "APP",
          },
        ],
      },
      imgHeight: 0,
    };
  },
  mounted() {
    this.getfourArticle();
    this.initImageHeight();
    window.addEventListener("resize", this.getImageHeight);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getImageHeight);
  },
  computed: {
    navCarousels() {
      return this.dataList.map((p) => p.imgURL);
    },
  },
  watch: {},
  methods: {
    initImageHeight() {
      this.$nextTick(() => {
        const img = document.querySelector(".el-carousel__item.is-active img");
        img.onload = () => {
          this.imgHeight = img ? img.height + 50 : 0;
        };
      });
    },
    getImageHeight() {
      const img = document.querySelector(".el-carousel__item.is-active img");
      this.imgHeight = img ? img.height + 50 : 0;
    },
    async clicka8060378(item) {
      console.log(item);
    },
    async clicka06922af() {},

    async clickfd5da416(item) {
      console.log(item);
      this.$router.push({
        path: "article",
        query: { articleID: item.articleID },
      });
    },
    async clicka82af208() {},
    // async clicka47f29eb (article) {
    //   console.log(article.articleID);
    //   let dicinput = {
    //     articleID: article.articleID
    //   }
    //   const res = await DALINEWEB_ARTICLECENTER_V0X0X1_getArticleInfo(dicinput)
    //   const articleInfo = res.content.resContent[0]
    // },
    //在此注释下方添加自定义函数或事件
    // async freshPage () {
    //   const resImgList = await DALINEWEB_IMGCENTER_V0X0X1_getImgList({
    //     imgType: 4,
    //     records: 1

    //   });
    //   const imgdataList = JSON.parse(JSON.stringify(resImgList.retContent));
    //   this.imgList = JSON.parse(imgdataList.retContent).imgList
    // },
    async getfourArticle() {
      let dicInput = {
        ArticleType: 41,
        records: 4,
        sort_rules: "orderID",
      };
      const res = await DALINEWEB_ARTICLECENTER_V0X0X1_getArticlewithToped(
        dicInput
      );
      let articleList = res.content.resContent;
      // this.section2.carousels = articleList;
      for (let index = 0; index < articleList.length; index++) {
        articleList[index]["title"] =
          articleList[index]["articleTitle"];
        articleList[index]["keyword1"] = "";
        articleList[index]["keyword2"] =
          articleList[index]["articleSummary"];
        articleList[index]["img"] =
          articleList[index]["articleImg"];
      }
      this.gallery = articleList;
    },
  },
};
</script>
<style lang="scss" scoped>
.el-carousel__item {
  cursor: initial;
}
.section1-img {
  width: 100%;
}
.el-carousel__item {
  img {
    width: 100%;
  }
}
.title {
  position: relative;
  padding-bottom: 0.5em;
  margin-bottom: 1em;
  text-align: center;
  font: 500 2em/1.5 "PingFang SC";
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 2em;
    height: 0.15em;
    background: #e74c3c;
  }
}

section.section2 {
  padding: 2rem;
  margin-top: 4em;
  h4 {
    @extend .title;
  }
  main {
    // height: 800px;
    position: relative;
    // display: flex;
    // justify-content: space-around;
    // align-items: center;
    img {
      object-fit: cover;
    }
  }
  footer {
  }
}
#Integratedbusinessdevelopmentplatform {
  padding-bottom: 50px;
}
/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
#d76d116a {
  width: 100vw;
  height: 50vh;
  position: relative;
}
#d76d116a {
  background-image: url("../assets/img-a14a7e29.png");
  background-size: cover;
}
#a628b0b6 {
  margin-top: 100px;
  @extend .title;
}

#cd8dd947 {
  width: 1219px;
  height: 341px;
  margin-top: 60px;
}
#cd8dd947 {
  background-color: rgb(255, 255, 255);
  border-radius: 20px 20px 20px 20px;
  box-shadow: 4px 6px 10px rgba(0, 0, 0, 0.2);
}
#cd8dd947 {
  vertical-align: middle;
}
#a67cc977 {
  width: 1920px;
  height: 982px;
  position: relative;
  margin-top: 100px;
}
#a67cc977 {
  background-image: url("../assets/img-b6a2b06f.png");
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#c8f6e675 {
  padding-top: 0px;
}
#c8f6e675 {
  text-align: center;
  color: #000;
  font-size: 45px;
  font-family: PingFang SC;
}
#fc2ad011 {
  width: 70%;
  position: relative;
  margin-top: 60px;
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
}
#a692b7af {
  position: relative;
  padding-bottom: 1px;
  display: inline-block;
  vertical-align: middle;
  /* width: 300px; */
  /* height: 1200px; */
}
#fd5da416 {
  width: 315px;
  height: 263px;
  padding-left: 1px;
  margin-top: 20px;
}
#fd5da416 {
  cursor: pointer;
}
#a06922af {
  width: 220px;
  height: 170px;
  margin-top: 30px;
}
#a06922af {
  cursor: pointer;
}
#a8060378 {
  width: 286px;
  height: 170px;
  margin-top: 20px;
}
#a8060378 {
  cursor: pointer;
}
#a82af208 {
  width: 286px;
  height: 170px;
  margin-top: 20px;
}
#a82af208 {
  cursor: pointer;
}
#a47f29eb {
  /* width: 1213px;
  height: 844px; */
  display: inline-block;
  vertical-align: middle;
  object-fit: cover;
  padding-left: 100px;
}
#a47f29eb {
  cursor: pointer;
}
#a336a01c {
  margin-top: 4em;
  @extend .title;
}

#af6960c3 {
  margin-top: 2em;
}
#a4a60e20 {
  width: 918px;
  height: 615px;
  display: inline-block;
  vertical-align: middle;
}
#db61ee99 {
  width: 918px;
  height: 514px;
}
#db61ee99 {
  vertical-align: middle;
}
#f8621104 {
  margin-top: 40px;
}
#f8621104 {
  text-align: center;
  color: #141620;
  font-size: 43px;
  font-family: PingFang SC;
}
#a2e9a8a3 {
  width: 918px;
  height: 615px;
  // margin-left: 100px;
  display: inline-block;
  vertical-align: middle;
}
#a8d0981a {
  width: 918px;
  height: 514px;
}
#a8d0981a {
  vertical-align: middle;
}

.label {
  font-size: calc(1vw + 1em);
  // margin-top: 0.5em;
  text-align: center;
  color: #141620;
  font-family: PingFang SC;
}
#a24b34e4 {
  width: 918px;
  height: 615px;
  // margin-left: 100px;
  display: inline-block;
  vertical-align: middle;
}
#aec47d5c {
  width: 918px;
  height: 514px;
}
#aec47d5c {
  vertical-align: middle;
}
#a7f512a5 {
  margin-top: 40px;
}
#a7f512a5 {
  text-align: left;
  color: #141620;
  font-size: 43px;
  font-family: PingFang SC;
}
#acb674b1 {
  width: 918px;
  height: 615px;
  // margin-left: 100px;
  display: inline-block;
  vertical-align: middle;
}
#ad01bb37 {
  width: 918px;
  height: 514px;
}
#ad01bb37 {
  vertical-align: middle;
}
#a42bc07e {
  margin-top: 40px;
}
#a42bc07e {
  text-align: left;
  color: #141620;
  font-size: 43px;
  font-family: PingFang SC;
}
#a8dc5777 {
  width: 918px;
  height: 615px;
  // margin-left: 100px;
  display: inline-block;
  vertical-align: middle;
}
#a18bb9bf {
  width: 918px;
  height: 514px;
}
#a18bb9bf {
  vertical-align: middle;
}
#ad8e5478 {
  margin-top: 40px;
}
#ad8e5478 {
  text-align: center;
  color: #141620;
  font-size: 43px;
  font-family: PingFang SC;
}
#a0fdb898 {
  width: 918px;
  height: 615px;
  // margin-left: 100px;
  display: inline-block;
  vertical-align: middle;
}
#a31526a9 {
  width: 918px;
  height: 514px;
}
#a31526a9 {
  vertical-align: middle;
}
#b3e576ff {
  margin-top: 40px;
}
#b3e576ff {
  text-align: center;
  color: #141620;
  font-size: 43px;
  font-family: PingFang SC;
}
#b9efdd5e {
  width: 918px;
  height: 615px;
  // margin-left: 100px;
  display: inline-block;
  vertical-align: middle;
}
#ab288f7a {
  width: 918px;
  height: 514px;
}
#ab288f7a {
  vertical-align: middle;
}
#f4ab7d23 {
  margin-top: 40px;
}
#f4ab7d23 {
  text-align: center;
  color: #141620;
  font-size: 43px;
  font-family: PingFang SC;
}
#deef1b91 {
  width: 918px;
  height: 615px;
  // margin-left: 100px;
  display: inline-block;
  vertical-align: middle;
}
#f8a368c6 {
  width: 918px;
  height: 514px;
}
#f8a368c6 {
  vertical-align: middle;
}
#a67e158d {
  margin-top: 40px;
}
#a67e158d {
  text-align: center;
  color: #141620;
  font-size: 43px;
  font-family: PingFang SC;
}
#a383d1ed {
  width: 150px;
  position: fixed;
  z-index: 50;
  top: 2182px;
  left: 1740px;
}
#a383d1ed {
  background-color: rgb(20, 22, 32);
  border-radius: 5px 5px 5px 5px;
}
/*在此注释下方添加自定义样式*/
#a47f29 {
  width: 1213px;
}
.flex-wrap {
  display: flex;
  justify-content: center;
  margin-top: 4em;
  padding: 0 15vw;
  img {
    width: 100%;
  }
}
</style>
